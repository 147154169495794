import React from 'react';
import { Link } from "gatsby-plugin-react-i18next";

import './button.component.scss';

export const Button = ({ children, className, buttonType, size, ...rest }) => {
    if (rest.to) {
        return <Link className={`hap-button ${className || ''} ${buttonType ? ('type-' + buttonType) : ''} ${size ? ('size-' + size) : ''}`} {...rest}>{children}</Link>;
    }
    else if (rest.href) {
        return <a className={`hap-button ${className || ''} ${buttonType ? ('type-' + buttonType) : ''} ${size ? ('size-' + size) : ''}`} {...rest}>{children}</a>;
    }
    else {
        return <button className={`hap-button ${className || ''} ${buttonType ? ('type-' + buttonType) : ''} ${size ? ('size-' + size) : ''}`} {...rest}>{children}</button>;
    }
};