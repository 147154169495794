import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormContext } from '../utils/form.context';

import './form-control.component.scss';

export const FormControl = ({ children, className, label, name, largeLabel=false, ...rest }) => {
    const { t } = useTranslation();

    return <FormContext.Consumer> 
        { errors => {
            const errorBlock = Array.isArray(errors) ? errors.find(k => k.property === name) : null;
            return <div className={`hap-FormControl ${errorBlock ? 'error-state' : ''} ${className || ''}`} {...rest}>
                    <label className={largeLabel ? 'large' : ''}>{ label }</label>
                    { children }
                    { errorBlock && errorBlock.errors.map(error => 
                        <span key={error} className="error t-sans">{ t(error) }</span>
                    )}
                </div>;
            }
        }
    </FormContext.Consumer>;
};