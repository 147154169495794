import React, { useEffect } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Link } from 'gatsby-plugin-react-i18next';
import { Navbar } from './navbar.component';
import { SecureLayout } from './secure-layout.component';
import BackButton from '../assets/back-button.svg';
import LogoIcon from '../assets/logo-icon.svg';

import './register-layout.component.scss';
import { useUser } from '../hooks/use-user.hook';

const menuItems = t => [
    {
        title: t('FAQ'),
        to: '/faqs',
        isButton: false
    },
    {
        title: t('Features'),
        to: '/features',
        isButton: false
    },
    {
        title: t('Contact Us'),
        to: '/#contact-us',
        isButton: false
    },
    {
        title: t('Login'),
        to: '/account/login',
        isButton: false
    },
    {
        title: t('Corporations'),
        to: '/corporations',
        isButton: false
    },
    {
        title: t('Test Kit'),
        to: '/register',
        isButton: true
    }
];

const menuItemsSecure = t => [
    {
        title: t('FAQ'),
        to: '/',
        isButton: false
    },
    {
        title: t('Dashboard'),
        to: '/dashboard',
        isButton: false
    },
    {
        title: t('Logout'),
        to: '/logout',
        isButton: false
    },
    {
        title: t('Test Kit'),
        to: '/register',
        isButton: true
    }
];

export const RegisterLayout = ({ 
    children, 
    disableSecureLayout, 
    className, 
    backLocation, 
    hideBackBtn = false, 
    title, 
    ...rest }) => {
    const { t } = useI18next();

    const { loggedIn } = useUser();

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, [])

    if (!title) {
        title = t('Test kit registration');
    }

    return <SecureLayout {...rest} disableSecureLayout={disableSecureLayout} className={['register-layout', className].join(' ')}>
        <Navbar
            className="navbar-mobile"
            left={!hideBackBtn && <Link to={backLocation}><BackButton /></Link>}
            center={title}
            menuItems={loggedIn ? menuItemsSecure(t) : menuItems(t)}
        />
        {loggedIn ? (
            <Navbar
                className="navbar-desktop"
                left={<LogoIcon />}
                isLoggedInDesktop={true}
            />
        ) : (
            <Navbar
                className="navbar-desktop"
                left={<LogoIcon />}
                menuItems={menuItems(t)}
            />
        )}
        <div className={`title-block-desktop${className.includes('create-account--check-items') ? ' title-block-desktop--check-items': ''}`}>
            <Link className="back-button-link" to={backLocation}><BackButton /></Link>
            <span className="t-sans t-gray9">{title}</span>
        </div>
        {children}
    </SecureLayout>;
};