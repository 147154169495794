import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Button } from '../../components/button.component';
import { FormControl } from '../../components/form-control.component';
import { Input } from '../../components/input.component';
import { FormContext } from '../../utils/form.context';
import { requestPasswordReset } from '../../services/users.service';
import { RegisterLayout } from '../../components/register-layout.component';

export default function ResetPasswordAccount(props) {

    const { t, navigate } = useI18next();

    const dispatch = useDispatch();

    const [formValues, setFormValues] = useState({});

    const [errors, setErrors] = useState(null);

    const [isSuccess, setSuccess] = useState(false);

    const onResetPassword = useCallback((e) => {
        e.preventDefault();
        setErrors(null);
        requestPasswordReset(formValues.emailAddress)
            .then(response => {
                setSuccess(true);
            })
            .catch(error => {
                setErrors(error.response.data.message);
            });
    }, [formValues, setErrors, navigate]);

    const handleOnChange = useCallback(e => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }, [formValues, setFormValues]);

    return <RegisterLayout disableSecureLayout={true} title={t('Reset password')} className="create-account" backLocation="/account/register">
        <div className="section">
            <FormContext.Provider value={errors}>
                <form onSubmit={onResetPassword} className="flex-form">
                    <FormControl label={t('Email')} name="emailAddress">
                        <Input name="emailAddress" placeholder="taro.yamada@gmail.com" value={formValues.emailAddress || ''} onChange={handleOnChange} />
                    </FormControl>
                    
                    {isSuccess ? (
                        <p className="success-message">{t('Reset password link has been sent to your email inbox.')}</p>
                    ) : (
                        <div className="buttons space-top">
                            <Button type="submit">{t('Send reset password link')}</Button>
                        </div>
                    )}
                </form>
            </FormContext.Provider>
        </div>
    </RegisterLayout>;
}
